


export class MemberBenefits
{
    active: boolean;
    production: boolean;
    memberId: string;
    subscriber: Subscriber;
    primaryCareProvider: PrimaryCareProvider;
    hemiBenefitList: HemiBenefitList[];
    planDetails: PlanDetails;
    benefits: PlanBenefitNetwork;
    limitations: { [key: string]: Limitations };
    coverage: { [key: string]: Coverage };
}

export class Subscriber
{
  phiId: string;
  firstName: string;
  lastName: string;
  prefix: string;
  suffix: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  birthdate: string;
  gender: string;
}
        
export class PrimaryCareProvider
{
  firstName: string;
  middleName: string;
  lastName: string;
  prefix: string;
  suffix: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  telephone: string;
  npi: string;
}

export class HemiBenefitList
{
    description: string;
    summary: string;
}

export class PlanDetails
{
  startDate: string;
  endDate: string;
  typeCode: string;
  type: string;
  description: string;
  coverageBasis: string;
  eligibilityStatus: string;
  serviceTypesSummary: { [key: string]: ServiceTypeDetails };
  summary: { [key: string]: string };
}

export class Limitations
{
  serviceCode: string;
  service: string;
  msgCode: string;
  msg: string;
  coverage: string;
  timePeriodCode: string;
  timePeriod: string;
  benefit: string;
  remaining: string;
  unitCode: string;
  unit: string;
  preAuth: string;
  inNetwork: string;
  summary: string;
}

export class  PlanBenefitNetwork
{
  inNetwork: PlanBenefitScope;
  outOfNetwork: PlanBenefitScope;
}
export class PlanBenefitScope
{
  family: PlanBenefitAmounts[];
  individual: PlanBenefitAmounts[];
}

export class PlanBenefitAmounts
{
  description: string;
  deductible: number;
  deductibleRemaining: number;
  outOfPocket: number;
  outOfPocketRemaining: number;
}

export class Coverage
{
  hemiCode: string;
  hemiDescription: string;
  serviceTypes: { [key: string]: ServiceTypes };
}

export class ServiceTypes
{
  serviceTypeCode: string;
  details: ServiceType;
  groups: { [key: string]: Groups };
}

export class Groups
{
  groupCode: string;
  groupDescription: string;
  qualifiers: { [key: string]: Qualifiers };
}

export class Qualifiers
{
  qualifierCode: string;
  qualifierDescription: string;
  timePeriodCode: string;
  timePeriod: string;
  preAuth: string;
  coIns: string;
  coPay: string;
  deductible: string;
  summaryDescription: string;
  summaryBenefits: string;
  sortKey: string;
}

export class ServiceType
{
  id: number;
  code: string;
  description: string;
  enable: string;
  hemicategory:number;
}

export class ServiceTypeMsg
{
  id: number;
  msg: string;
  display: string;
}

export class ServiceTypeDetails
{
  tier: { [key: string]: string };
  tierKeys: { [key: string]: string };
  summary: string;
}